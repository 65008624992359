
  import { HasPropertyTabs } from "@/mixins/properties/has-tabs";
  import { HasPropertyConfig } from "@/mixins/properties/has-config";
  import { HasObjectMethods } from "@/mixins/has-object-methods";
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import { Component, Mixins, Ref } from "vue-property-decorator";
  import { formatDate } from "@/utils/formatters";
  import { Action } from "vuex-class";

  @Component({
    components: {
      PropertyPartGeneralInfoBlock: () => import("@/views/auth/properties/parts/general/info-block.vue"),

      // General
      PropertyPartDesktopLabel: () => import("@/views/auth/properties/parts/desktop/label.vue"),
      PropertyPartDesktopNumber: () => import("@/views/auth/properties/parts/desktop/number.vue"),
      PropertyPartDesktopTextinput: () => import("@/views/auth/properties/parts/desktop/textinput.vue"),
      PropertyPartDesktopTextarea: () => import("@/views/auth/properties/parts/desktop/textarea.vue"),
      PropertyPartDesktopWysiwyg: () => import("@/views/auth/properties/parts/desktop/wysiwyg.vue"),
      PropertyPartDesktopYesNo: () => import("@/views/auth/properties/parts/desktop/yes-no.vue"),
      PropertyPartDesktopMoney: () => import("@/views/auth/properties/parts/desktop/money.vue"),
      PropertyPartDesktopSelect: () => import("@/views/auth/properties/parts/desktop/select.vue"),
      PropertyPartDesktopMultiselect: () => import("@/views/auth/properties/parts/desktop/multiselect.vue"),
      PropertyPartDesktopDate: () => import("@/views/auth/properties/parts/desktop/date.vue"),
      PropertyPartDesktopRooms: () => import("@/views/auth/properties/parts/desktop/rooms.vue"),

      // Custom
      PropertyPartDesktopSubtypes: () => import("@/views/auth/properties/parts/desktop/subtypes.vue"),
      PropertyPartDesktopModalityNotes: () => import("@/views/auth/properties/parts/desktop/modality-notes.vue"),

      FormSubmit: () => import("@/components/forms/submit.vue"),
      FormBase: () => import("@/components/forms/base.vue"),
    },
  })
  export default class PagePropertyCreateDesktop extends Mixins(HasPropertyTabs, HasPropertyConfig, HasObjectMethods, HasFormErrors) {
    @Action("property/update") updateProperty!: (payload: PropertyUpdatePayload) => Promise<Property>;

    @Ref() form!: FormClass;

    /**
     * @name Last changed date
     * @description Get a readable format of when the property was last changed.
     */
    get lastChangeDate() {
      return this.property ? formatDate(this.property.updated_at) : "";
    }

    /**
     * @name After mounted
     * @description Handle the event after all data is generated and the form has mounted.
     * - Update the current tab based on the url hash.
     *
     */
    async afterMounted() {
      this.$nextTick(() => {
        this.handleTabsUpdate();
      });
    }

    /**
     * Update the payload characteristics based on the path provided.
     * When a . is detected in the path go a level deeper in the object.
     *
     */
    updatePropertyCharacteristic(path: any, value: any, callback?: (v: any) => void) {
      if (!this.payload) {
        return;
      }

      path.split(".").reduce((acc: any, key: string, index: number, arr: any) => {
        if (index === arr.length - 1) {
          this.$set(acc, key, value);
        }

        return acc[key];
      }, this.payload.characteristics);

      if (callback) {
        callback(value);
      }
    }

    async submit() {
      if (!this.property || !this.payload) {
        return;
      }

      // @ts-ignore
      this.payload.description = this.payload!.characteristics!._meta.description;

      try {
        await this.updateProperty(this.payload);

        this.$toast.open({ message: "Pandgegevens aangevuld!", type: "success" });
      } catch (e) {
        this.errorResponse = this.formatErrors(e);

        if (this.errorResponse && this.errorResponse.errors && this.errorResponse.errors.length > 0) {
          this.errorResponse.errors.forEach((error: any) => {
            if (error.localizedMessage) {
              this.$toast.open({ message: error.localizedMessage, type: "error" });
            } else {
              this.$toast.open({ message: error.message, type: "error" });
            }
          });
        } else {
          this.$toast.open({ message: "Oops er ging iets mis", type: "error" });
        }
      }

      this.form.reset();
    }

    handleInvalidSubmit() {
      this.$toast.open({ message: `${this.form.allErrors!.length} veld${this.form.allErrors!.length > 1 ? "en zijn" : " is"}  onjuist ingevuld` as string, type: "error" });
    }
  }
